import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/layout.tsx";
import Intro from "../components/v8-jit/intro";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Intro mdxType="Intro" />
    <hr></hr>
    <h3>{`منابع`}</h3>
    <p>{`[`}{`1`}{`]`}{`: `}<a parentName="p" {...{
        "href": "https://v8.dev/blog/turbofan-jit"
      }}>{`https://v8.dev/blog/turbofan-jit`}</a></p>
    <p>{`[`}{`2`}{`]`}{`: `}<a parentName="p" {...{
        "href": "https://darksi.de/4.how-to-start-jitting/"
      }}>{`https://darksi.de/4.how-to-start-jitting/`}</a></p>
    <p>{`[`}{`3`}{`]`}{`: `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=mjHtadilm00&ab_channel=WebRebels"
      }}>{`https://www.youtube.com/watch?v=mjHtadilm00&ab_channel=WebRebels`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      